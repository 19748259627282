import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

// Define an interface for props that include a name and a variant
interface WithProps {
    name: string
    variant: string
    onClick?: () => void
}

const usePlannerStore = createStore({
    completedLessons:
        JSON.parse(localStorage.getItem("completedLessons")) || [],
})

// Define constants for the different lesson status variants
const notStartedVariantName = "Not started"
const finishedVariantName = "Finished"

// This function is an override that adds a 'variant' prop to a component based
// on if the lesson is listed as completed in the Planner store
export function withLessonStatus(
    Component: ComponentType<WithProps>
): ComponentType<WithProps> {
    return (props) => {
        const [store, setStore] = usePlannerStore()
        let variant = notStartedVariantName
        // Extract the lesson number from props.name
        const lessonId = props.name.match(/\d+/)[0]
        console.log("checking lesson", lessonId)
        console.log("completed lessons", store.completedLessons)
        const completedLessons = store.completedLessons || []
        if (completedLessons.includes(lessonId)) {
            variant = finishedVariantName
        }
        return (
            <Component
                {...props}
                variant={variant}
                data-planner-button="status"
            />
        )
    }
}

// This function is an override that adds an onClick handler to a component
// The handler adds the lesson to the list of completed lessons
export function withLessonCompletedButton(
    Component: ComponentType<WithProps>
): ComponentType<WithProps> {
    return (props) => {
        const [store, setStore] = usePlannerStore()
        console.log("completed lessons", store.completedLessons)
        console.log("PROPS", props)
        const handleClick = () => {
            // Extract the lesson number from props.name
            const lessonId = props.name.match(/\d+/)[0]
            console.log("completed lesson", lessonId)
            window?.posthog.capture("Clicked Complete Lesson", { lessonId })
            const completedLessons = store.completedLessons || []
            if (!completedLessons.includes(lessonId)) {
                const updatedLessons = [...completedLessons, lessonId]
                setStore({ completedLessons: updatedLessons })
                localStorage.setItem(
                    "completedLessons",
                    JSON.stringify(updatedLessons)
                )
            }
        }

        return (
            <div onClick={handleClick} data-planner-button="finish">
                <Component {...props} />
            </div>
        )
    }
}

export function withLessonFileDownload(
    Component: ComponentType<WithProps>
): ComponentType<WithProps> {
    return (props) => {
        const handleClick = () => {
            // Extract the lesson number from props.name
            const lessonId = window.location.pathname.match(/\d+/)[0]
            window?.posthog?.capture("Clicked Download File", { lessonId })
        }

        return (
            <div onClick={handleClick}>
                <Component {...props} />
            </div>
        )
    }
}
